function adjustTextAreaHeight(el) {
  // const initial_height = parseFloat(getComputedStyle(el).height);

  // // height と clientHeight の差分 px
  // // padding 量だけど box-sizing で変わるので実際の差分から取得
  // const diff = el.clientHeight - initial_height;

  // height を 0 にした状態の scrollHeight が必要な高さ
  setHeightPx(0);
  // const noscroll_height = el.scrollHeight - diff;
  const noscroll_height = el.scrollHeight;
  setHeightPx(noscroll_height);

  // Firefox は高さ 0 のときにスクロールバーなしの高さなのでここで終わり
  // 続けるとちゃんと動かない
  if (navigator.userAgent.includes('Firefox')) return;

  // Chrome はスクロールバーあり状態の必要高さなので右端折返しがあるとその分隙間がある
  // scrollHeight と clientHeight が異なるところまで縮める
  let height = noscroll_height;
  while (el.scrollHeight === el.clientHeight) {
    setHeightPx(--height);
  }
  const final_height = height + 3;

  // いったんスクロールバーない状態にしないと折り返しあり状態になっている
  setHeightPx(noscroll_height);
  // 再計算
  el.scrollHeight;
  setHeightPx(final_height);

  function setHeightPx(height) {
    el.style.height = height + 'px';
  }
}

const flexTextarea = document.getElementById('js-flexTextarea');
if (flexTextarea != null) {
  flexTextarea.oninput = function() {
    adjustTextAreaHeight(flexTextarea);
  };
}
