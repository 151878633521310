const touch =
  'ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

const zoomimage = document.getElementById('carouselECdetail');
const zoompreview = document.getElementById('js-zoompreview');

if (zoomimage != null) {
  if (touch == false) {
    var evt = new Event(),
      m = new Magnifier(evt);
    m.attach({
      thumb: '.js-zoomimage',
      largeWrapper: 'js-zoompreview'
    });
  }
  zoomimage.addEventListener('mouseout', function() {
    zoompreview.classList.add('hidden');
  });
  zoomimage.addEventListener('mouseover', function() {
    zoompreview.classList.remove('hidden');
  });
}
