import Swiper from 'swiper';

const elmSwiper = document.querySelectorAll('.js-ec-slider');
const elmPager = document.querySelectorAll('.js-ec-pagination');
const elmBtnPrev = document.querySelectorAll('.js-ec-button-prev');
const elmBtnNext = document.querySelectorAll('.js-ec-button-next');
if (elmSwiper.length > 0) {
  for (let i = 0; i < elmSwiper.length; i++) {
    elmSwiper[i].className += i;
    elmPager[i].className += i;
    elmBtnPrev[i].className += i;
    elmBtnNext[i].className += i;
    new Swiper('.js-ec-slider' + i, {
      slidesPerView: 5,
      slidesPerGroup: 5,
      spaceBetween: 0,
      watchOverflow: true,
      pagination: {
        el: '.js-ec-pagination' + i,
        clickable: true,
        type: 'bullets'
      },
      navigation: {
        prevEl: '.js-ec-button-prev' + i,
        nextEl: '.js-ec-button-next' + i
      },
      breakpoints: {
        575: {
          // <= 575px（xs - sm）
          slidesPerView: 2,
          slidesPerGroup: 2,
          spaceBetween: 0
        },
        767: {
          // <= 767px（sm - md）
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 0
        },
        991: {
          // <= 991px（md - lg）
          slidesPerView: 4,
          slidesPerGroup: 4,
          spaceBetween: 0
        }
      }
    });
    new Swiper('.-maxcol4.js-ec-slider' + i, {
      slidesPerView: 4,
      slidesPerGroup: 4,
      spaceBetween: 0,
      watchOverflow: true,
      pagination: {
        el: '.js-ec-pagination' + i,
        clickable: true,
        type: 'bullets'
      },
      navigation: {
        prevEl: '.js-ec-button-prev' + i,
        nextEl: '.js-ec-button-next' + i
      },
      breakpoints: {
        575: {
          // <= 575px（xs - sm）
          slidesPerView: 2,
          slidesPerGroup: 2,
          spaceBetween: 0
        },
        991: {
          // <= 991px（md - lg）
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 0
        }
      }
    });
  }
}
