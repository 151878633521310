import SmoothScroll from 'smooth-scroll';

SmoothScroll('a[href*="#"]', {
  ignore: '[data-scroll-ignore]',
  header: '[data-scroll-header]',
  topOnEmptyHash: true,
  speed: 600,
  speedAsDuration: true,
  easing: 'easeInOutCubic',
  updateURL: false
});
